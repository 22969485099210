import { strapi } from '../instance';
import {
  CONTENT_TYPES,
  LandingPageLanguage,
  SingleType,
  StaticContentApplicant,
} from '../model';
import { getLanguageLocale } from '../utils/getLanguageLocale';

export const getStaticContentApplicant = async (
  language?: LandingPageLanguage | null,
) => {
  const res = await strapi
    .find<SingleType<StaticContentApplicant>>(
      CONTENT_TYPES.STATIC_CONTENT_APPLICANT,
      {
        locale: getLanguageLocale(language),
        populate: {
          footer: {
            fields: '*',
            populate: {
              links: { fields: '*' },
            },
          },
          cookie_consent_dialog: {
            fields: '*',
          },
          questionnaire: {
            field: '*',
            populate: {
              contact_details_badges: { fields: '*' },
            },
          },
          hero_section: {
            fields: '*',
            populate: {
              hero_advantage: {
                fields: '*',
                populate: { icon: { fields: '*' } },
              },
            },
          },
          video_section: {
            fields: '*',
            populate: {
              video: { fields: '*' },
              video_thumbnail: { fields: '*' },
              navigation_item: { fields: '*' },
            },
          },
          services_section: {
            fields: '*',
            populate: {
              navigation_item: { fields: '*' },
              process_navigation_item: { fields: '*' },
              process_step: {
                fields: '*',
                populate: { icon: { fields: '*' } },
              },
              process_advantage: { fields: '*' },
            },
          },
          reviews_section: {
            fields: '*',
            populate: {
              navigation_item: { fields: '*' },
            },
          },
          questions_section: {
            fields: '*',
            populate: {
              navigation_item: { fields: '*' },
            },
          },
        },
      },
    )
    .catch((err) => {
      console.log('getStaticContentApplicant error', err);
      throw err;
    });

  console.log('getStaticContentApplicant', res);

  if (!res.data.attributes) throw new Error('No static content found');

  return res.data;
};
