export * from './content-types';
export * from './landing-page';
export * from './questionnaire';
export * from './static-content';

export const CONTENT_TYPES = {
  LANDING_PAGES: 'landing-pages',
  STATIC_CONTENT: 'static-content',
  STATIC_CONTENT_APPLICANT: 'static-content-applicant',
  QUESTIONNAIRES: 'questionnaires',
  LEADS: 'leads',
} as const;
