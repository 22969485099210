import React from 'react';
import { AboutUsSectionContent } from '../SectionMapper';
import { StaticContent } from '../../../lib/strapi';
import { SectionContainer } from '../SectionContainer';
import ReactMarkdown from 'react-markdown';
import { Button } from '../../../components/Button';
import { questionnaireRoute } from '../../../config/navigation.config';
import { ArrowRight } from '../../../components/Icons';
import { Statistics } from '../Video/components/Statistics';

type AboutUsSectionProps = {
  content: AboutUsSectionContent;
  staticContent: StaticContent['about_us_section'];
};

export const AboutUsSection: React.FC<AboutUsSectionProps> = (props) => {
  // Extracting content from props
  const { title, description, aboutus_image, enabled } = props.content;
  const imageSrc = aboutus_image?.data?.attributes?.url;

  return enabled ? (
    <SectionContainer data-section="about-us" fullWidth>
      <div className="relative mx-auto max-w-[1400px] overflow-hidden">
        <div className="diagonal-bg-clip absolute left-0 top-[15%] -z-10 h-full w-[1400px] md:top-[20%] lg:top-[40%]" />
        <div className="content-wrapper mt-8 flex flex-col items-center justify-start gap-x-12 md:mt-16 lg:mt-0 lg:flex-row lg:py-24">
          <div className="max-w-[490px]">
            <div
              id={props.staticContent?.navigation_item?.anchor_id ?? undefined}
              style={{ scrollMarginTop: '64px' }}
              className="flex flex-col"
            >
              <h2 className="text-base leading-tight md:text-2xl">{title}</h2>
              <div className="mb-4 md:mt-2 lg:mb-12">
                {description ? (
                  <ReactMarkdown className="article text-sm md:text-base">
                    {description}
                  </ReactMarkdown>
                ) : undefined}
              </div>
              {props.staticContent?.call_to_action_button_label ? (
                <div className="mx-auto flex-shrink-0 md:mx-0">
                  <Button
                    data-testid="about-us-button"
                    variant="secondary"
                    className="w-auto text-[0.9rem]"
                    to={`/${questionnaireRoute}`}
                    label={props.staticContent?.call_to_action_button_label}
                    Icon={<ArrowRight className="stroke-[white]" />}
                  />
                </div>
              ) : null}
            </div>
          </div>
          <div className="mx-auto mb-8 mt-8 md:mb-16 lg:my-0">
            <div data-testid="image-wrapper" className="group">
              <img
                style={{
                  width: '540px',
                  height: '360px',
                  borderRadius: '20px',
                }}
                src={imageSrc}
                alt={'Alternate text'}
              />
            </div>
          </div>
        </div>
        <div className="content-wrapper">
          <Statistics items={props.content.statistics} />
        </div>
      </div>
    </SectionContainer>
  ) : (
    <></>
  );
};
